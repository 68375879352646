import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './css/ImageSlider.css';

import imagenCabañaFamiliar from '../../images/Cabañas/CabañaFamiliarSlider.png';
import imagenCabañaDoble from '../../images/Cabañas/CabañaDobleSlider.png';
import imagenCabañaDobleDeluxe from '../../images/Cabañas/CabañaDobleDeluxe.png';

import imagenSpa from '../../images/Experiencia/ImagenSpa.png';
import imagenTours from '../../images/Experiencia/ImagenTours.png'; 
import imagenReserva from '../../images/Reserva/ImagenReserva.jpg';
import Calendar from '../Calendar/Calendar';

import Observatorio1 from '../../images/Experiencia/Observatorio/Observatorio1.jpg';
import Observatorio2 from '../../images/Experiencia/Observatorio/Observatorio2.jpg';
import Observatorio3 from '../../images/Experiencia/Observatorio/Observatorio3.jpg';
import Observatorio4 from '../../images/Experiencia/Observatorio/Observatorio4.jpg';
import Observatorio5 from '../../images/Experiencia/Observatorio/Observatorio5.jpg';

import Inicio1 from '../../images/Inicio/Inicio1.jpg';
import Inicio2 from '../../images/Inicio/Inicio2.jpg';
import Inicio3 from '../../images/Inicio/Inicio3.jpg';
import Inicio4 from '../../images/Inicio/Inicio4.jpg';
import Inicio5 from '../../images/Inicio/Inicio5.jpg';
import Inicio6 from '../../images/Inicio/Inicio6.jpg';
import Inicio7 from '../../images/Inicio/Inicio7.jpg';



const ImageSlider = ({ tipo }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null);

  const handleVideoLoaded = () => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1; 
      videoRef.current.loop = true;
      videoRef.current.play();
    }
    setVideoLoaded(true);
  };

  let imagen;
  let texto;
  let textoExperiencia;
  let SliderOverlay;

  switch (tipo) {
    case 'Inicio':
      texto = 'CONOCE NUESTRO REFUGIO';
      SliderOverlay = 'Slider-overlay absolute bottom-0 w-full sm:h-5vh md:h-8vh lg:h-10vh xl:h-15vh text-xs font-bold lg:flex';
      break;
    case 'CabañaFamiliar':
      imagen = imagenCabañaFamiliar;
      texto = 'CONOCE NUESTRA CABAÑA FAMILIAR';
      SliderOverlay = 'Slider-overlay absolute bottom-0 w-full sm:h-5vh md:h-8vh lg:h-10vh xl:h-15vh text-xs font-bold  lg:flex';
      break;
    case 'CabañaDoble':
      imagen = imagenCabañaDoble;
      texto = 'CONOCE NUESTRA CABAÑA DOBLE';
      SliderOverlay = 'Slider-overlay absolute bottom-0 w-full sm:h-5vh md:h-8vh lg:h-10vh xl:h-15vh text-xs font-bold lg:flex';
      break;
    case 'Observatorio':
      texto = 'TOUR ASTRONÓMICO DE LUJO EN';
      textoExperiencia = 'OBSERVATORIO GALILEO';
      SliderOverlay = 'TextoExperiencias'
      break;
    case 'Spa':
      imagen = imagenSpa;
      texto = 'EXCLUSIVA SALA DE';
      textoExperiencia = 'MASAJES';
      SliderOverlay = 'TextoExperiencias'
      break;
    case 'Tours':
      imagen = imagenTours;
      texto = 'SORPRÉNDETE EN';
      textoExperiencia = 'PISCO ELQUI';
      SliderOverlay = 'TextoExperiencias'
      break; 
    case 'Reserva':
      imagen = imagenReserva;
      texto = 'RESERVA TU CABAÑA'; 
      SliderOverlay = 'Slider-overlay absolute bottom-0 w-full sm:h-5vh md:h-8vh lg:h-10vh xl:h-15vh hidden lg:flex';
      break;
    default:
      imagen = imagenCabañaDobleDeluxe;
      texto = 'CONOCE NUESTRA CABAÑA DOBLE DELUXE';
      SliderOverlay = 'Slider-overlay absolute bottom-0 w-full sm:h-5vh md:h-8vh lg:h-10vh xl:h-15vh hidden lg:flex';
  }

  return (
    <div className="image-slider relative">
      <div className='overlay'></div>
      <div className='Fondo'></div>
  
      {tipo === 'Observatorio' ? (
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          loop={true}
          className="slider-inicio object-contain rounded-lg shadow-lg"
        >
          <SwiperSlide>
            <img src={Observatorio1} alt="Observatorio 1" className="w-screen h-screen object-cover" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Observatorio2} alt="Observatorio 2" className="w-screen h-screen object-cover" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Observatorio3} alt="Observatorio 3" className="w-screen h-screen object-cover" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Observatorio4} alt="Observatorio 4" className="w-screen h-screen object-cover" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Observatorio5} alt="Observatorio 5" className="w-screen h-screen object-cover" />
          </SwiperSlide>
        </Swiper>
      ) : tipo === 'Inicio' ? (
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          loop={true}
          className="slider-inicio object-contain rounded-lg shadow-lg"
        >
          <SwiperSlide>
            <img src={Inicio1} alt="Inicio 1" className="w-screen h-screen object-cover" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Inicio2} alt="Inicio 2" className="w-screen h-screen object-cover" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Inicio3} alt="Inicio 3" className="w-screen h-screen object-cover" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Inicio4} alt="Inicio 4" className="w-screen h-screen object-cover" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Inicio5} alt="Inicio 5" className="w-screen h-screen object-cover" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Inicio6} alt="Inicio 6" className="w-screen h-screen object-cover" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Inicio7} alt="Inicio 7" className="w-screen h-screen object-cover" />
          </SwiperSlide>
        </Swiper>
      ) : (
        <img src={imagen} className="w-screen h-screen object-cover" />
      )}
  
      {(tipo === 'Inicio' || tipo === 'Reserva') && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30">
          <Calendar />
        </div>
      )}

      <div className={`${SliderOverlay} absolute bottom-0 left-1/2 transform -translate-x-1/2 w-1/2 h-1/6 flex flex-col justify-center items-center z-20 bg-black/50`}>
        <p className="text-white">{texto}</p>
        {(tipo === 'Observatorio' || tipo === 'Spa' || tipo === 'Tours') && (
          <p className='Destacado text-white'>{textoExperiencia}</p>
        )}
        {(tipo !== 'Observatorio' && tipo !== 'Spa' && tipo !== 'Tours') && (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="arrow-down cursor-pointer text-white" onClick={() => window.scrollTo({ top: 710, behavior: 'smooth' })}>
            <path fillRule="evenodd" d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
          </svg>
        )}
      </div>

    </div>
  );
};

export default ImageSlider;