import React, { useState, useEffect } from 'react';
import './css/CabañasDraft.css';
import CabañaFamiliar from '../../images/Cabañas/CabañaFamiliar.png';
import CabañaDoble from '../../images/Cabañas/CabañaDoble.png';
import CabañaDobleDeluxe from '../../images/Cabañas/CabañaDobleDeluxe.png';


const CabañasDraft = () => {
    const imagesCabaña= [CabañaFamiliar, CabañaDoble, CabañaDobleDeluxe];
    const [imageIndex, setImageIndex] = useState(0);
    const [imageSrc, setImageSrcFamiliar] = useState(imagesCabaña[imageIndex]);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        let timer;
        if (isHovering) {
            timer = setInterval(() => {
                if (isHovering) {
                    setImageIndex((prevIndex) => {
                        const nextIndex = (prevIndex + 1) % imagesCabaña.length;
                        return  nextIndex;
                    });
                }
            }, 1000);
        } else {
            setImageIndex(1);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isHovering]);
    useEffect(() => {
        setImageSrcFamiliar(imagesCabaña[imageIndex]);
    }, [imageIndex ,imagesCabaña]);

   
    return (
        <div className="containerCabañas">
         
                <a href="/Cabañas"
                    onMouseEnter={() => setIsHovering(true)} // Inicia el cambio de imágenes.
                    onMouseLeave={() => setIsHovering(false)} // Detiene el cambio de imágenes.
                >
                    <div className='descriptionCabañas'>
                        <div className='imageContainer'>
                        <div className='overlay'></div>
                            <img src={imageSrc} alt="Cabaña Familiar" />
                        </div> 
                        <p><strong>CONOCE NUESTRAS CABAÑAS</strong>
                        <br /><span className="precio">Temporada Alta: Septiembre - Marzo 
                        <br />Temporada Baja: Abril - Agosto</span>
                        </p>
                    </div>
                </a>
          

        </div>
    );
}

export default CabañasDraft;