import React, { useState } from 'react';
import './css/TiposCabañas.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import ButtonBuscar from '../Button/ButtonBuscar';

import CabañaFamiliar1 from '../../images/Cabañas/Carrusel/CabañaFamiliar1.jpg';
import CabañaFamiliar2 from '../../images/Cabañas/Carrusel/CabañaFamiliar2.jpeg';
import CabañaFamiliar3 from '../../images/Cabañas/Carrusel/CabañaFamiliar3.jpg';
import CabañaFamiliar4 from '../../images/Cabañas/Carrusel/CabañaFamiliar4.jpg';

import CabañaMatrimonial1 from '../../images/Cabañas/Carrusel/CabañaMatrimonial1.jpg';
import CabañaMatrimonial2 from '../../images/Cabañas/Carrusel/CabañaMatrimonial2.jpg';
import CabañaMatrimonial3 from '../../images/Cabañas/Carrusel/CabañaMatrimonial3.jpg';
import CabañaMatrimonial4 from '../../images/Cabañas/Carrusel/CabañaMatrimonial4.jpg';

import CabañaSuperior1 from '../../images/Cabañas/Carrusel/CabañaSuperior1.jpg';
import CabañaSuperior2 from '../../images/Cabañas/Carrusel/CabañaSuperior2.jpg';
import CabañaSuperior3 from '../../images/Cabañas/Carrusel/CabañaSuperior3.jpg';
import CabañaSuperior4 from '../../images/Cabañas/Carrusel/CabañaSuperior4.jpg';

const TiposCabañas = ({ marginBottom }) => {
  const [isHovering, setIsHovering] = useState({
    familiar: false,
    doble: false,
    dobleDeluxe: false,
  });

  const cabañas = [
    {
      name: 'CABAÑA FAMILIAR',
      price1: 'Temporada Alta $130.000',
      price2: 'Temporada Baja $120.000',
      maxGuests: 4,
      images: [CabañaFamiliar1, CabañaFamiliar2, CabañaFamiliar3, CabañaFamiliar4],
      link: 'Cabañas/CabañaFamiliar',
    },
    {
      name: 'CABAÑA MATRIMONIAL',
      price1: 'Temporada Alta $110.000',
      price2: 'Temporada Baja $100.000',
      maxGuests: 2,
      images: [CabañaMatrimonial1, CabañaMatrimonial2, CabañaMatrimonial3, CabañaMatrimonial4],
      link: 'Cabañas/CabañaDoble',
    },
    {
      name: 'CABAÑA MATRIMONIAL SUPERIOR',
      price1: 'Temporada Alta $120.000',
      price2: 'Temporada Baja $110.000',
      maxGuests: 2,
      images: [CabañaSuperior1, CabañaSuperior2, CabañaSuperior3, CabañaSuperior4],
      link: 'Cabañas/CabañaDobleDeluxe',
    },
  ];

  return (
    <div className="containerTiposCabañas">
      <div className="tiposCabañasRow">
        {cabañas.map((cabaña) => (
          <div className="tipoCabañaCard" style={{ marginBottom: marginBottom }} key={cabaña.name}>
            <a
              href={cabaña.link}
              onMouseEnter={() => setIsHovering((prev) => ({ ...prev, [cabaña.name]: true }))}
              onMouseLeave={() => setIsHovering((prev) => ({ ...prev, [cabaña.name]: false }))}
            >
              <div className="imageTipoContainer">
                <Swiper
                  modules={[Navigation, Pagination, Autoplay]}
                  navigation
                  pagination={{ clickable: true }}
                  autoplay={{ delay: 3000 }}
                  loop={true}
                  className="cabaña-carousel"
                >
                  {cabaña.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img src={image} alt={`${cabaña.name} ${index}`} className="w-full h-full object-cover" />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <p className="Tipo">{cabaña.name}</p>
              <p className="Personas">{cabaña.price1}</p>
              <p className="Personas">{cabaña.price2}</p>
              <p className="Personas">Máximo {cabaña.maxGuests} huéspedes</p>
              <div className='divBotonBuscar'>
                <ButtonBuscar />
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TiposCabañas;
