import React from 'react';
import { Link } from 'react-router-dom';  // Importación añadida para que funcione el Link
import './css/Footer.css';
import logo from '../../images/Logos/LogoSidebar.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import tripadvisor from '../../images/Logos/tripadvisor-32.png'
import ButtonHablemos from '../Button/ButtonHablemos';
const Footer = () => {
  return (
    <div className="footer">
      <div className="logo-container-footer">
        <img src={logo} alt="Imagen Logo" className='logo-footer' />
        {/*<Link className='buttonFooter' to={`/anfitrion`}>PANEL ANFITRION</Link>*/}
      </div>
      <div className='column-footer-description'>
        <p className='p1'>Te esperamos en Refugio Misterios del Elqui</p>
        <p className='p2'>Ubicado en el corazón del Valle de Elqui, nuestro refugio es un santuario de paz y serenidad, donde la naturaleza y la comodidad se encuentran para ofrecerle una experiencia inolvidable.</p>

      </div>
      <div className="column-footer-rows">
        <p className="contact-title">Contacto</p>
        <p className="contact-info"><a href="https://maps.app.goo.gl/cLHo7RPePLQfDHwB6">Arturo Prat S/N, Pisco Elqui Chile</a></p>
        <p className="contact-info"><a href="tel:+56944004032">+56 9 4400 40 32</a></p>
        <p className="contact-info"><a href="mailto:reservas@misteriosdelelqui.cl">reservas@misteriosdelelqui.cl</a></p>
        <ButtonHablemos />

      </div>
      <div className="column-footer-rows">
        <p className="contact-title">Encuentranos en</p>
        <div className="column-footer-columns gap-x-5">
          <a href="https://www.instagram.com/rmisteriosdelelqui" target="_blank" rel="noopener noreferrer" className="rrss">
            <FontAwesomeIcon icon={faInstagram} size="" className="text-white" />
          </a>
          <a href="https://www.facebook.com/misteriosdelelqui" target="_blank" rel="noopener noreferrer" className="rrss">
            <FontAwesomeIcon icon={faFacebook} size="" className="text-white" />
          </a>
          <a href="https://www.tripadvisor.cl/Hotel_Review-g664072-d607240-Reviews-Refugio_Misterios_Del_Elqui-Pisco_Elqui_Coquimbo_Region.html" target="_blank" rel="noopener noreferrer" className="rrss">
        <img src={tripadvisor} alt="Logo Tripadvisor" className='text-white' />
          </a>
        </div>
        <div className='column-footer-description'>
          <p className='p2'><span className='text-white'>Desarrollado por</span> <a className='font-bold' href="http://www.bucont.com">bucont.com</a></p>

        </div>
      </div>
      <div> <a href="https://wa.me/56995109187" className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} className='whatsapp-icon' /></a></div> {/* botón whats flotante */}
    </div>
  );
};

export default Footer;