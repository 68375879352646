import React from 'react';
import './css/Cabañas.css';
import './css/inicio.css';
import Header from '../components/Header/header';
import Footer from '../components/Footer/Footer';
import TiposCabañas from '../components/Card/TiposCabañas';
import DraftCabañas from '../components/Card/CabañasDraft';


const Cabañas = () => {
  return (
    <div className="CabañasPage" >
      <Header scrollPosition={1} className="fixed-header-Cabañas" />
      <div className="cuerpoCabañas">
        <div className='Cabaña-Inicio' data-aos="fade-up">
            <DraftCabañas />
        </div>
        <div className='tipos-cabañas' >
          <TiposCabañas marginBottom="10%"/>
        </div> 
        <div className='FooterCabañas'>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Cabañas;
